<template>
  <footer class="y-footer">
    <div class="footer__info">
      <NuxtLink
        external
        to="https://github.com/DeOrderBook/v1-core/blob/main/LICENSE.md"
        target="_blank"
        class="footer__info__copyright"
      >
        &copy; Copyright {{ year }} DeOrderBook
      </NuxtLink>
      <div class="footer__doc-links">
        <NuxtLink
          v-for="link in DOCUMENT_LINKS"
          :key="link.title"
          :to="link.url"
          target="_blank"
          class="footer__doc-link"
        >
          {{ link.title }}
        </NuxtLink>
      </div>
    </div>
    <SocialLinks />
  </footer>
</template>
<script setup lang="ts">
import SocialLinks from './SocialLinks.vue'
const { t } = useCustomI18n()

const DOCUMENT_LINKS: { title: string; url: string }[] = [
  {
    title: t('labels.terms-of-service'),
    url: '/Terms_of_Service.pdf',
  },
  {
    title: t('labels.privacy-policy'),
    url: '/Privacy_Policy.pdf',
  },
  {
    title: t('labels.disclaimer'),
    url: '/Disclaimer.pdf',
  },
]

const year = new Date().getFullYear()
</script>

<style lang="postcss" scoped>
.y-footer {
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  width: 100vw;
  padding: 20px 16px;
}
.footer__info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
}
.footer__info__copyright {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  color: #fff;
  &:hover {
    text-decoration: underline;
  }
}
.footer__doc-links {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
}
.footer__doc-link {
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1.5;
  color: #fff;
  &:hover {
    text-decoration: underline;
  }
}

@media screen and (max-width: 425px) {
  .y-footer {
    height: auto;
    padding-top: 0;
  }
}
</style>
