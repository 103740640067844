<template>
  <div class="tx-modal__actions">
    <Btn
      v-if="!!txModalData?.onCancel && txModalState === TxModalState.CONFIRM"
      height="40"
      title="Cancel"
      @click="handleClose"
    >
      {{ $t('labels.cancel') }}
    </Btn>
    <Btn
      v-if="!!txModalData?.onSubmit && txModalState === TxModalState.CONFIRM"
      bg
      height="40"
      title="Confirm"
      @click="handleSubmit"
    >
      {{ $t('labels.confirm') }}
    </Btn>
    <div
      v-if="txModalState === TxModalState.SUCCESS"
      class="tx-modal__success-actions"
    >
      <template v-if="isAccountLinkVisible">
        <NuxtLink
          :to="actionButton.url"
          class="account__link"
          :external="actionButton.url.startsWith('http')"
          :target="actionButton.url.startsWith('http') ? '_blank' : '_self'"
          @click="handleClose"
        >
          <p>{{ $t(actionButton.text) }}</p>
        </NuxtLink>
      </template>
      <template v-if="isExplorerLinkVisible">
        <NuxtLink
          v-if="txModalState === TxModalState.SUCCESS && !!txHash"
          :to="`https://explorer.deorderbook.com/tx/${txHash}${
            txModalData?.type === TxType.BULLET_BUY ? '?action=BuyBULLET' : ''
          }`"
          target="_blank"
          rel="noreferrer"
          class="explorer__link"
        >
          <p>{{ $t('labels.view-in') }} Explorer</p>
          <SVGArrow width="16" height="16" class="link__arrow"></SVGArrow>
        </NuxtLink>
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
import Btn from '@base/components/basic/Btn.vue'
import { TxModalState, TxType } from '@base/types/transaction'
import SVGArrow from '~icons/ic/baseline-arrow-outward'

const txModalStore = useTxModalStore()
const { hideTxModal } = txModalStore
const {
  txModalData,
  txModalState,
  txHash,
  isAccountLinkVisible,
  isExplorerLinkVisible,
  actionButton,
} = toRefs(txModalStore)

const handleClose = () => {
  if (txModalState.value === TxModalState.HIDDEN) return
  if (txModalData.value?.onCancel) txModalData.value?.onCancel()
  hideTxModal()
}
const handleSubmit = () => {
  if (txModalState.value === TxModalState.HIDDEN) return
  if (txModalData.value?.onSubmit) txModalData.value?.onSubmit()
}
const { links } = useDeOrderbookLinks()
</script>
<style lang="postcss" scoped>
.tx-modal__actions {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.action-link {
}
.explorer__link {
  display: flex;
  align-items: center;
  opacity: 0.75;
  transition: all 200ms ease-in-out;
  &:hover {
    opacity: 1;
  }
}
.tx-modal__success-actions {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.account__link-btn {
  width: 100%;
  & span {
    width: 100%;
  }
}
.account__link {
  --button-blue: #64acff;
  --button-bg-border-blue: #2463c5;
  --button-border-blue: #4098ff;
  width: 100%;
  padding: 8px 12px;
  color: var(--button-blue);
  background: linear-gradient(0deg, #0f52ba 0%, #041836 50%);
  border: 1px solid var(--button-bg-border-blue);
  border-radius: 16px;
}
</style>
