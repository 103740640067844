<template>
  <div class="media__group">
    <NuxtLink
      v-for="link in SOCIAL_LINKS"
      :key="link.title"
      :to="link.url"
      target="_blank"
      :title="link.title"
    >
      <img :src="link.icon" :alt="link.title" class="icon" />
    </NuxtLink>
  </div>
</template>
<script setup lang="ts">
import GithubIcon from '@base/assets/img/icons/socials/github.svg'
import TwitterIcon from '@base/assets/img/icons/socials/twitter.svg'
import DiscordIcon from '@base/assets/img/icons/socials/discord.svg'
import YoutubeIcon from '@base/assets/img/icons/socials/youtube.svg'
import MirrorIcon from '@base/assets/img/icons/socials/mirror.svg'
const SOCIAL_LINKS: {
  title: string
  url: string
  icon: string
}[] = [
  {
    title: 'GitHub',
    url: 'https://www.github.com/DeOrderBook',
    icon: GithubIcon,
  },
  {
    title: 'Twitter',
    url: 'https://twitter.com/DeOrderBook',
    icon: TwitterIcon,
  },
  {
    title: 'Discord',
    url: 'https://discord.gg/f5kDvarwcX',
    icon: DiscordIcon,
  },
  {
    title: 'YouTube',
    url: 'https://www.youtube.com/@deorderbook',
    icon: YoutubeIcon,
  },
  {
    title: 'Mirror',
    url: 'https://mirror.xyz/0x2341BDd8a7047943586E948b1ca04e5E548Cd06D',
    icon: MirrorIcon,
  },
]
</script>
<style lang="postcss" scoped>
.media__group {
  position: relative;
  z-index: 1;
  display: flex;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
}
.icon {
  width: 20px;
  height: 20px;
  color: #fff;
  & path {
    fill: #fff;
  }
}
@media screen and (max-width: 425px) {
}
</style>
