<template>
  <div
    v-if="isSwitchNetworkBarVisible"
    class="switch-network-container"
    :class="isSwitchNetworkBarVisible ? '--visible' : ''"
  >
    <div class="switch-network-content">
      {{ $t('base.network-not-supported') }}
      <div class="y-button-html" @click="handleSwitchNetwork()">
        {{ $t('base.switch-network') }}
      </div>
      <!-- <el-icon
        :size="16"
        class="notification-close-icon"
        :title="$t('labels.close')"
        @click="(e) => hideSwitchNetworkBar()"
      >
        <Close />
      </el-icon> -->
    </div>
  </div>
</template>
<script lang="ts" setup>
/**
 * When you are in an unsupported network, you will be prompted to switch networks
 * @notice It should be added manually under the header in the layout, deorderbook-base currently does not support layout
 */
import { Close } from '@element-plus/icons-vue'

const notificationBarStore = useNotificationbarStore()
const { openNetworkDropdown } = notificationBarStore
const { isSwitchNetworkBarVisible } = toRefs(notificationBarStore)

const handleSwitchNetwork = () => {
  openNetworkDropdown()
}
</script>
<style lang="postcss" scoped>
.switch-network-container {
  display: none;
  width: 100%;
  padding: 0 16px 16px;

  &.--visible {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    &::before {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';
      background: rgba(0, 0, 0, 0.7);
    }
  }
}

.switch-network-content {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #fff;
  background: #26262b;
  border-radius: 16px;
  & .y-button-html {
    margin-top: 0;
    margin-left: 8px;
  }
}

/* .notification-close-icon.el-icon {
  position: absolute;
  top: 50%;
  right: 16px;
  cursor: pointer;
  transform: translateY(-50%);
} */

@media screen and (max-width: 768px) {
  .switch-network-content {
    height: auto;
    font-size: 12px;
    line-height: 14px;
  }
}

@media screen and (max-width: 640px) {
  .switch-network-content {
    flex-direction: column;
    gap: 8px;
    padding-right: 40px;
    text-align: center;
    & .y-button-html {
      width: 100%;
      margin-top: 8px;
      margin-left: 0;
    }
  }
}
</style>
