<template>
  <div v-if="!!contract" class="tx-modal__contract-info">
    <p class="tx-modal__contract-title">
      Contract:
      <span>{{ contract.name }}</span>
    </p>
    <NuxtLink
      v-if="!!explorerLink && !!contract.address"
      :to="`${explorerLink}/address/${contract.address}`"
      target="_blank"
      class="explorer__link"
    >
      <p>{{ trimStr(contract.address) }}</p>
      <SVGArrow width="16" height="16" class="link__arrow"></SVGArrow>
    </NuxtLink>
  </div>
</template>
<script lang="ts" setup>
import type { HODL } from '@base/types/tokens'
import SVGArrow from '~icons/ic/baseline-arrow-outward'

const txModalStore = useTxModalStore()
const { txModalData } = toRefs(txModalStore)

const { explorerLink } = useNetwork()

const contract = computed(() => {
  if (!txModalData.value) return null

  const { contract: _contract } = useContract(txModalData.value?.type)

  if (!_contract.value) return null

  if (
    !!txModalData.value?.token &&
    Object.keys(_contract.value).includes(txModalData.value?.token)
  ) {
    return {
      name: _contract.value.name,
      address: _contract.value[txModalData.value?.token as HODL],
    }
  }

  return { name: _contract.value.name, address: _contract.value?.address }
})

const trimStr = (str: string | undefined) => {
  if (!str?.length) return ''
  return str.substring(0, 7) + '...' + str.substring(str.length - 4)
}
</script>
<style lang="postcss" scoped>
.tx-modal__contract-info {
  display: flex;
  gap: 4px;
  align-items: center;
}
.tx-modal__contract-title {
  & span {
    font-weight: 600;
  }
}
.explorer__link {
  display: flex;
  align-items: center;
  text-decoration: underline;
  opacity: 0.6;
  transition: all 200ms ease-in-out;
  &:hover {
    opacity: 1;
  }
}
.link__arrow {
  margin-left: 4px;
}
</style>
