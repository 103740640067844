<template>
  <div class="tx-modal__body">
    <div class="tx-modal__text-info">
      <img
        src="@base/assets/img/loading@mini.webp"
        alt="Pending"
        class="gif --loading"
      />
      <h5 class="tx-modal__body__title">{{ $t('labels.pending') }}...</h5>
      <template v-if="!!txHash && !!explorerLink">
        <div class="tx-hash">
          <p class="tx-hash__title">{{ $t('labels.transaction') }}:</p>
          <NuxtLink
            :to="`${explorerLink}/tx/${txHash}`"
            target="_blank"
            class="explorer__link"
          >
            <p>{{ trimAddr(txHash) }}</p>
            <SVGArrow width="16" height="16" class="link__arrow"></SVGArrow>
          </NuxtLink>
        </div>
      </template>
      <ApproveInfo v-if="txModalData?.type === TxType.APPROVE && !txHash" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import ApproveInfo from './ApproveInfo.vue'
import { TxType } from '@base/types/transaction'
import SVGArrow from '~icons/ic/baseline-arrow-outward'
const { txHash, txModalData } = toRefs(useTxModalStore())
const { explorerLink } = useNetwork()
</script>
<style lang="postcss" scoped>
.tx-modal__body {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding-top: 24px;
}
.tx-modal__text-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
}
.gif {
  margin-bottom: 8px;
}
.tx-modal__body__title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.tx-hash {
  display: flex;
  gap: 4px;
  align-items: center;
}
.tx-has__title {
}
.explorer__link {
  display: flex;
  align-items: center;
  text-decoration: underline;
  opacity: 0.6;
  transition: all 200ms ease-in-out;
  &:hover {
    opacity: 1;
  }
}
</style>
