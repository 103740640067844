<template>
  <div id="tx-modal__confirmation" class="tx-modal__body">
    <div class="tx-modal__text-info">
      <h5 class="tx-modal__body__title">Confirm Transaction</h5>
    </div>
    <TxContractInfo />
    <div v-show="!!txModalData?.rows?.length" class="tx-modal__rows">
      <RowValue
        v-for="(row, index) in txModalData?.rows"
        :key="index"
        :data="row"
      />
    </div>
    <div v-show="!!txModalData?.multiRows?.length" class="tx-modal__multi-rows">
      <div
        v-for="(rows, i) in txModalData?.multiRows"
        :key="i"
        class="tx-modal__rows"
      >
        <RowValue v-for="(row, j) in rows" :key="`${i}-${j}`" :data="row" />
      </div>
    </div>
    <TxGasInfo v-if="isGasInfoVisible" />
  </div>
</template>
<script lang="ts" setup>
import RowValue from './RowValue.vue'
import TxContractInfo from './TxContractInfo.vue'
import TxGasInfo from './TxGasInfo.vue'

const txModalStore = useTxModalStore()
const { txModalData } = toRefs(txModalStore)

const isGasInfoVisible = computed(() => {
  return false
  // return !isTestnet.value
})
</script>
<style lang="postcss" scoped>
.tx-modal__body {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 24px 0;
}
.tx-modal__text-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
}
.tx-modal__body__title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.tx-modal__rows {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-height: 400px;
  padding: 16px;
  overflow-y: auto;
  border: 1px solid #4d4d4d;
  border-radius: 16px;
}
.tx-modal__multi-rows {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
</style>
