<template>
  <el-dialog
    ref="dialogRef"
    v-model="isDialogOpen"
    :title="dialogTitle"
    class="tx-modal-dialog"
    center
    destroy-on-close
    align-center
    lock-scroll
    modal
    :close-on-click-modal="isCloseEnabled"
    :close-on-press-escape="isCloseEnabled"
    :show-close="isCloseEnabled"
    @close="handleClose"
  >
    <template #default>
      <template v-if="txModalState === TxModalState.CONFIRM">
        <TxConfirmation />
      </template>
      <template v-else-if="txModalState === TxModalState.PENDING">
        <TxPending />
      </template>
      <template v-else-if="txModalState === TxModalState.SUCCESS">
        <TxSuccess />
      </template>
      <template v-else-if="txModalState === TxModalState.FAILURE">
        <TxFailure />
      </template>
    </template>
    <template #footer>
      <TxModalActions />
    </template>
  </el-dialog>
</template>
<script lang="ts" setup>
import TxConfirmation from './TxConfirmation.vue'
import TxPending from './TxPending.vue'
import TxSuccess from './TxSuccess.vue'
import TxModalActions from './TxModalActions.vue'
import TxFailure from './TxFailure.vue'
import { TxModalState } from '@base/types/transaction'
import { TxTypeText } from '@base/components/others/tx-modal/constants'

const dialogRef = ref(null)
const txModalStore = useTxModalStore()
const { hideTxModal } = txModalStore
const { txModalData, txModalState, isOpen } = toRefs(txModalStore)

const isDialogOpen = computed(
  () => txModalState.value !== TxModalState.HIDDEN && isOpen.value,
)

const isCloseEnabled = computed(() =>
  [TxModalState.SUCCESS, TxModalState.FAILURE, TxModalState.CONFIRM].includes(
    txModalState.value,
  ),
)

const dialogTitle = computed(() => {
  if (!txModalData.value) return ''
  return TxTypeText[txModalData.value.type].title
})

const handleClose = () => {
  if (txModalState.value === TxModalState.HIDDEN) return
  if (txModalData.value?.onCancel) txModalData.value?.onCancel()
  hideTxModal()
}
</script>
<style lang="postcss">
.tx-modal-dialog.el-dialog {
  width: auto;
  min-width: 400px;
  max-height: calc(100dvh - 32px);
  padding: 40px;
  overflow-y: auto;
  color: var(--color);
  background: radial-gradient(
    49.67% 49.67% at 88.76% 5.88%,
    #323232 0%,
    #26262b 95.31%
  );
  border: 0.5px solid #4d4d4d;
  border-radius: 16px;

  & .el-dialog__header {
    position: relative;
    padding: 0;
    margin-right: 0;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
  }
  & .el-dialog__headerbtn {
    top: 50%;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    padding: 1px;
    font-size: 24px;
    border-radius: 50%;
    transition: all 100ms ease-in-out;
    transform: translateY(-50%);
    & .el-icon.el-dialog__close {
      width: 16px;
      height: 16px;
    }
    &:hover {
      background: #4d4d4d;
      & .el-icon.el-dialog__close {
        color: var(--color);
      }
    }
  }
  & .el-dialog__body {
    padding: 0;
  }
  & .el-dialog__footer {
    padding: 0;
    margin-top: 0;
  }
}

@media (max-width: 768px) {
  .tx-modal-dialog.el-dialog {
    min-width: 320px;
    padding: 24px;
  }
}
@media (max-width: 425px) {
  .tx-modal-dialog.el-dialog {
    max-width: calc(100dvw - 32px);
    padding: 16px;
  }
}
</style>
