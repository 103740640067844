<template>
  <div class="row-value">
    <p v-if="!!data.label" class="row-value__label">
      {{ data.label }}
    </p>
    <div v-else-if="!!data.optionInfo" lass="row-value__value-main-label">
      <div class="row-value__value-main --left">
        <img
          v-if="!!data.optionInfo?.token"
          :src="getTokenIcon(data.optionInfo?.token)"
          :title="getOriginalSymbol(data.optionInfo?.token)"
          :alt="getOriginalSymbol(data.optionInfo?.token)"
          class="row-value__value__icon --caption"
        />
        <p class="row-value__value">
          {{ getOriginalSymbol(data.optionInfo.token) }}
          <span v-if="!!data.optionInfo?.nickname">
            {{ data.optionInfo.nickname }}
          </span>
        </p>
      </div>
      <div class="caption-extra-info --size-lg">
        <p>
          <span>Strike:</span>
          {{ data.optionInfo.strike }}
        </p>
        <p>
          <span>Expiry:</span>
          {{ data.optionInfo.expiry }}
        </p>
      </div>
    </div>
    <div class="row-value__values-list">
      <template v-for="(value, index) in data.values" :key="index">
        <div class="row-value__value-container">
          <div v-if="!!value?.caption" class="row-value__value-main-label">
            <div class="row-value__value-main">
              <img
                v-if="!!value?.caption?.token"
                :src="getTokenIcon(value?.caption?.token)"
                :alt="getOriginalSymbol(value?.caption?.token)"
                class="row-value__value__icon --caption"
              />
              <p class="row-value__value --caption">
                {{
                  checkTokenExist(value?.caption?.label)
                    ? getOriginalSymbol(value?.caption?.label as AllTokenType)
                    : value?.caption?.label
                }}
              </p>
            </div>
            <div v-if="!!value.caption?.optionInfo" class="caption-extra-info">
              <p>
                <span>{{ $t('labels.strike') }}:</span>
                {{ value.caption.optionInfo.strike }}
              </p>
              <p>
                <span>{{ $t('labels.expiry') }}:</span>
                {{ value.caption.optionInfo.expiry }}
              </p>
            </div>
          </div>
          <div class="row-value__value-main">
            <img
              v-if="!!value?.token"
              :src="getTokenIcon(value.token)"
              :title="getOriginalSymbol(value.token)"
              :alt="getOriginalSymbol(value.token)"
              class="row-value__value__icon"
            />
            <img
              v-if="value.value === 'FREE'"
              src="@base/assets/img/free-icon.svg"
              title="FREE"
              alt="FREE"
              class="row-value__value__icon -free"
            />
            <NuxtLink
              v-if="!!value.link"
              :to="value.link"
              :external="true"
              target="_blank"
              class="row-value__link"
            >
              <p class="row-value__value">
                {{ value.value }}
              </p>
              <SVGArrow width="14" height="14" class="link__arrow"></SVGArrow>
            </NuxtLink>
            <p v-else-if="value.value !== 'FREE'" class="row-value__value">
              {{ value.value }}
            </p>
          </div>
          <p
            v-if="value?.subvalue !== undefined"
            class="row-value__value__subtext"
          >
            {{ value.subvalue }}
          </p>
        </div>
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
import type { PropType } from 'vue'
import type { AllTokenType } from 'deorderbook-sdk/types'
import { type TxModalRow } from '@base/types/transaction'
import SVGArrow from '~icons/ic/baseline-arrow-outward'
const props = defineProps({
  data: {
    type: Object as PropType<TxModalRow>,
    required: true,
    default: () => ({}) as TxModalRow,
  },
})
const { data } = toRefs(props)
const { getTokenIcon, getOriginalSymbol, checkTokenExist } = useTokens()
</script>
<style lang="postcss" scoped>
.row-value {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}
.row-value__label {
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  color: var(--color);
}
.row-value__values-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;
  justify-content: flex-start;
}
.row-value__value-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: flex-end;
  justify-content: flex-start;
}
.row-value__value-main-label {
  display: flex;
  flex-direction: column;
}
.row-value__value-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  &.--left {
    justify-content: flex-start;
  }
}
.row-value__value {
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  color: var(--color);
  &.--caption {
    font-size: 12px;
    line-height: 16px;
  }
}
.row-value__value__icon {
  width: 14px;
  height: 14px;
  margin-right: 4px;

  &.-free {
    width: 20px;
    height: 20px;
    margin-right: 0;
  }
  &.--caption {
    width: 12px;
    height: 12px;
  }
}
.row-value__value__subtext {
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  color: var(--color);
  opacity: 0.6;
}
.row-value__link {
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 200ms ease-in-out;
  &:hover {
    text-decoration: underline;
  }
}
.link__arrow {
  margin-left: 4px;
}
.caption-extra-info {
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 10px;
  & span {
    font-size: 8px;
    color: rgba(255, 255, 255, 0.6);
  }

  &.--size-lg {
    font-size: 12px;
    & span {
      font-size: 10px;
    }
  }
}
</style>
