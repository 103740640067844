import {
  sellTokenHODLAddress,
  DOBStakingPoolAddress,
  HODLStakingPoolsAddress,
  StakingPoolsAddress,
  uHODLAddress,
  MatchingMarketAddress,
} from 'deorderbook-sdk'
import type { HODL } from '@base/types/tokens'
import { TxType } from '@base/types/transaction'

type IContractHODL = {
  [key in HODL]?: string
}

type IContract = IContractHODL & {
  name: string
  address: string
}

export default function useContractAddress(txType: TxType | undefined) {
  const { getOriginalSymbol } = useTokens()
  const TX_CONTRACT_MAP: Record<TxType, IContract> = {
    [TxType.APPROVE]: {
      name: '',
      address: '',
    },
    [TxType.DEORDER]: {
      name: 'Staking Pools',
      address: StakingPoolsAddress,
    },
    [TxType.WITHDRAW]: {
      name: `${getOriginalSymbol('DOB')}  Staking Pool`,
      address: DOBStakingPoolAddress,
    },
    [TxType.HODL_STAKE]: {
      name: 'HODL Staking Pool',
      address: HODLStakingPoolsAddress,
    },
    [TxType.HODL_UNSTAKE]: {
      name: 'HODL Staking Pool',
      address: HODLStakingPoolsAddress,
    },
    [TxType.SNIPER_STAKE]: {
      name: 'Staking Pools',
      address: StakingPoolsAddress,
    },
    [TxType.SNIPER_UNSTAKE]: {
      name: 'Staking Pools',
      address: StakingPoolsAddress,
    },
    [TxType.UNWIND]: {
      name: 'Staking Pools',
      address: StakingPoolsAddress,
    },
    [TxType.EXERCISE]: {
      name: 'Staking Pools',
      address: StakingPoolsAddress,
    },
    [TxType.COLLECT]: {
      name: 'Staking Pools',
      address: StakingPoolsAddress,
    },
    [TxType.MINT]: {
      name: 'HODL Token',
      address: '',
      sellTokenHODL: sellTokenHODLAddress,
      uHODL: uHODLAddress,
    },
    [TxType.REDEEM]: {
      name: 'HODL Token',
      address: '',
      sellTokenHODL: sellTokenHODLAddress,
      uHODL: uHODLAddress,
    },
    [TxType.LOCK]: {
      name: `${getOriginalSymbol('DOB')}  Staking Pool`,
      address: DOBStakingPoolAddress,
    },
    [TxType.CLAIM]: {
      name: 'Staking Pools',
      address: StakingPoolsAddress,
    },
    [TxType.DOB_CLAIM]: {
      name: `${getOriginalSymbol('DOB')}  Staking Pool`,
      address: DOBStakingPoolAddress,
    },
    [TxType.BULLET_CLAIM]: {
      name: `${getOriginalSymbol('DOB')} Staking Pool`,
      address: DOBStakingPoolAddress,
    },
    [TxType.CLAIM_COLLECT]: {
      name: 'Staking Pools',
      address: StakingPoolsAddress,
    },
    [TxType.HODL_UNSTAKE_CLAIM]: {
      name: 'HODL Staking Pool',
      address: HODLStakingPoolsAddress,
    },
    [TxType.SNIPER_UNSTAKE_CLAIM]: {
      name: 'Staking Pools',
      address: StakingPoolsAddress,
    },
    [TxType.BULLET_SELL]: {
      name: 'Matching Market',
      address: MatchingMarketAddress,
    },
    [TxType.BULLET_UPDATE]: {
      name: 'Matching Market',
      address: MatchingMarketAddress,
    },
    [TxType.BULLET_CANCEL]: {
      name: 'Matching Market',
      address: MatchingMarketAddress,
    },
    [TxType.BULLET_BUY]: {
      name: 'Matching Market',
      address: MatchingMarketAddress,
    },
  }
  /**
   * @description The contract for the transaction type (txType)
   */
  const contract = computed(() => {
    if (!txType) return null
    return TX_CONTRACT_MAP[txType]
  })

  return { contract }
}
