<template>
  <div class="tx-modal__body">
    <div class="tx-modal__text-info">
      <img
        src="@base/assets/img/oracle-glitching.webp"
        alt="Failure"
        class="gif --loading"
      />
      <el-alert
        v-if="!!txError"
        :title="data.title"
        type="error"
        :description="data.description"
        :closable="false"
        effect="dark"
        class="tx-modal__error-alert"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
const { txError } = toRefs(useTxModalStore())

const data = computed(() => {
  return {
    title: isTxRejectedError(txError.value)
      ? 'Action Rejected'
      : 'Transaction Failed',
    description: isTxRejectedError(txError.value)
      ? 'The transaction was rejected by the user.'
      : 'The transaction may have failed. Please check the transaction details on Etherscan.',
  }
})
</script>
<style lang="postcss" scoped>
.tx-modal__body {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 24px 0;
}
.tx-modal__text-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
}
.gif {
  margin-bottom: 8px;
}
.tx-modal__error-alert.el-alert {
  max-width: calc(400px - 32px);
  background: #f56c6c11;
  border: 1px solid #f56c6c;
  border-radius: 8px;
  & :deep(.el-alert__content) {
    width: 100%;
  }
  & :deep(.el-alert__description) {
    margin: 0;
    text-align: center;
    word-break: break-word;
  }
}
</style>
