<template>
  <div class="tx-modal__gas-info">
    <h5 class="tx-modal__gas-info__title">Gas Price</h5>
    <el-radio-group
      v-if="!loading && gasInfo.length"
      v-model="_selectedGasType"
      class="tx-modal__gas-info__list"
      effect="dark"
    >
      <el-radio-button
        v-for="gas in gasInfo"
        :key="gas.type"
        :label="gas.type"
        class="tx-modal__gas-info__list-item"
      >
        {{ gas.label }}:
        <span>{{ gas.value }} {{ gas.unit }}</span>
      </el-radio-button>
    </el-radio-group>
    <template v-else-if="loading">
      <el-skeleton
        loading
        animated
        :count="3"
        class="tx-modal__gas-info__skeleton"
        effect="dark"
      >
        <template #template>
          <el-skeleton-item variant="rect" style="width: 130px; height: 38px" />
        </template>
      </el-skeleton>
    </template>
  </div>
</template>
<script lang="ts" setup>
import type { GasType } from '@base/composables/useGasInfo'
// @ts-ignore
const { data: gasInfo, loading, selectedGasType } = toRefs(useGasInfo())
const _selectedGasType = selectedGasType as unknown as Ref<keyof typeof GasType>
watch([_selectedGasType, gasInfo], ([newGasType, newGasInfo]) => {
  if (newGasType && newGasInfo) {
    const gasInfoItem = newGasInfo.find((gas) => gas.type === newGasType)
    if (gasInfoItem) {
      _selectedGasType.value = gasInfoItem.type
    }
  }
})
</script>
<style lang="postcss" scoped>
.tx-modal__gas-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.tx-modal__gas-info__title {
  font-size: 14px;
}
.tx-modal__gas-info__list {
  display: flex;
  /* flex-direction: column; */
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.tx-modal__gas-info__list-item.el-radio-button {
  /* width: 100%; */
  min-width: 130px;
}

:global(
    .tx-modal__gas-info__list-item.el-radio-button .el-radio-button__inner
  ) {
  width: 100%;
  padding: 12px 20px;
  font-size: 12px;
  color: #fff;
  color: rgba(255, 255, 255, 0.6);
  background: transparent;
  border: none;
  border-radius: 8px;
  box-shadow: none;
  transition: all 200ms ease-in-out;
}
:global(
    .tx-modal__gas-info__list-item.el-radio-button:hover:not(.is-disabled)
      .el-radio-button__inner
  ) {
  background: #45454d77;
}
:global(
    .tx-modal__gas-info__list-item.el-radio-button
      .el-radio-button__inner
      > span
  ) {
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
}
:global(
    .tx-modal__gas-info__list-item.el-radio-button.is-active
      .el-radio-button__inner
  ) {
  background: #45454d77;
}
:global(
    .tx-modal__gas-info__list-item.el-radio-button.is-focus
      .el-radio-button__inner
  ) {
  background: #45454d77;
}
.tx-modal__gas-info__skeleton {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  --el-skeleton-color: #303030;
  --el-skeleton-to-color: #424243;
}
</style>
