/**
 * @description layout init
 * @notice You need to make sure that changes to this file apply to all projects
 */
export const useLayout = () => {
  const { setIsLogged, user } = toRefs(useAppStore())
  const { checkCurrentChain } = useWalletStore()
  const { connectWallet, isDebugMode } = useWallet()

  onBeforeMount(() => {
    if (!checkCurrentChain()) return

    setIsLogged.value(
      !!localStorage.getItem('isLogged'),
      localStorage.getItem('walletType') ?? '',
    )
    if (user.value.isLogged || !!isDebugMode?.value) {
      connectWallet(user.value.walletType).catch((err) => {
        logTxError(err)
        useNotify({
          type: 'error',
          message: err.message,
        })
      })
    }
  })
}
