<template>
  <div class="approve-info">
    <div class="approve-info__header">
      <h5 class="approve-info__header__title">{{ $t('base.spending-cap') }}</h5>
      <p class="approve-info__header__description">
        {{ $t('base.spending-cap-desc') }}
      </p>
    </div>
    <div class="approve-info__options">
      <div class="approve-info__option --golden">
        <div class="approve-info__option__header">
          <h6 class="approve-info__option__header__title">
            {{ $t('base.use-site-suggestion') }}
          </h6>
          <div class="approve-info__option__header__tags">
            <p class="approve-info__option__header__tag">
              {{ $t('base.receommended') }}
            </p>
            <p class="approve-info__option__header__tag">
              {{ $t('base.save-gas-fee') }}
            </p>
          </div>
        </div>
        <ul class="approve-info__option__list">
          <li class="approve-info__option__list__item">
            <span>{{ $t('base.simplicity-ease') }}</span>
          </li>
          <li class="approve-info__option__list__item">
            <span>
              <strong>{{ $t('base.approve-once') }}</strong>
              {{ $t('base.to-save-gas-fees') }}
            </span>
          </li>
        </ul>
      </div>
      <div class="approve-info__option">
        <div class="approve-info__option__header">
          <h6 class="approve-info__option__header__title">
            {{ toTitleCase($t('labels.max')) }}
          </h6>
        </div>
        <ul class="approve-info__option__list">
          <li class="approve-info__option__list__item">
            <span>{{ $t('base.full-control-customize') }}</span>
          </li>
          <li class="approve-info__option__list__item">
            <span>{{ $t('base.pay-gas-fee-on-every-trade') }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup></script>
<style scoped lang="postcss">
.approve-info {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 370px;
  padding-top: 24px;
  margin-top: 24px;
  border-top: 1px solid #616161;
}
.approve-info__header {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  text-align: left;
}
.approve-info__header__title {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: #fff;
}
.approve-info__header__description {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #d2d2d2;
  opacity: 0.6;
}
.approve-info__options {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.approve-info__option {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 20px 16px;
  background: #212126;
  border: 0.5px solid #4d4d4d;
  border-radius: 10px;
  &.--golden {
    border-color: #ffc550;
  }
}
.approve-info__option__header {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
}
.approve-info__option__header__title {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: #fcfcfc;
  text-align: left;
  white-space: nowrap;
}
.approve-info__option__header__tags {
  display: flex;
  gap: 8px;
  align-items: center;
}
.approve-info__option__header__tag {
  padding: 1px 8px;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #ffc550;
  text-align: center;
  white-space: nowrap;
  border: 0.5px solid #ffc550;
  border-radius: 43px;
}
.approve-info__option__list {
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
  color: #d2d2d2;
  text-align: left;
  list-style-position: inside;
}
.approve-info__option__list__item {
  & span {
    margin-left: -4px;
  }
  & strong {
    font-weight: 600;
    color: #fcfcfc;
  }
}
</style>
