import type { MaybeRefOrGetter } from 'vue'

const deOrderbookLinks = {
  ui: 'https://app.deorderbook.com',
  bazaar: 'https://bazaar.deorderbook.com',
  nft: 'https://nft.deorderbook.com',
  pro: 'https://pro.deorderbook.com',
  explorer: 'https://explorer.deorderbook.com',
} as const

export type LinkKeyType = MaybeRefOrGetter<keyof typeof deOrderbookLinks>

/**
 * @description Hook for accessing external links related to DeOrderbook.
 * @export
 * @return {*}  {{
 *   links: typeof deOrderbookLinks
 *   openDeOrderbookLink: (key: LinkKeyType) => void
 * }}
 */
export function useDeOrderbookLinks(): {
  links: typeof deOrderbookLinks
  openDeOrderbookLink: (key: LinkKeyType) => void
}
export function useDeOrderbookLinks(linkKey: LinkKeyType): {
  links: typeof deOrderbookLinks
  openDeOrderbookLink: (key?: LinkKeyType) => void
}
export function useDeOrderbookLinks(linkKey?: LinkKeyType) {
  /**
   * Opens the specified DeOrderbook link in a new window.
   *
   * @param key - The key of the link to open.
   */
  const openDeOrderbookLink = (key = linkKey) => {
    if (!key) {
      throw new Error('must pass key to openDeOrderbookLink')
    }

    window.open(deOrderbookLinks[toValue(key)], '_blank')
  }
  return {
    links: deOrderbookLinks,
    openDeOrderbookLink,
  }
}

export default useDeOrderbookLinks
