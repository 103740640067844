<template>
  <div class="tx-modal__body">
    <div class="tx-modal__text-info">
      <img
        src="@base/assets/img/Dobia_Releases_Coins@mini.webp"
        alt="Success"
        class="gif --success"
      />
      <h5 class="tx-modal__body__title">Success</h5>
      <p v-show="!!txSubtitle" class="tx-modal__body__subtitle">
        {{ txSubtitle }}
      </p>
    </div>
    <div v-show="!!txModalData?.rows?.length" class="tx-modal__rows">
      <RowValue
        v-for="(row, index) in txModalData?.rows"
        :key="index"
        :data="row"
      />
    </div>
    <div v-show="!!txModalData?.multiRows?.length" class="tx-modal__multi-rows">
      <div
        v-for="(rows, i) in txModalData?.multiRows"
        :key="i"
        class="tx-modal__rows"
      >
        <RowValue v-for="(row, j) in rows" :key="`${i}-${j}`" :data="row" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import RowValue from './RowValue.vue'
import { TxTypeText } from '@base/components/others/tx-modal/constants'
import { TxModalState } from '@base/types/transaction'

const txModalStore = useTxModalStore()
const { txModalData, txModalState } = toRefs(txModalStore)

const txSubtitle = computed(() => {
  if (!txModalData.value || txModalState.value !== TxModalState.SUCCESS)
    return null
  return TxTypeText[txModalData.value.type].subtitle
})
</script>
<style lang="postcss" scoped>
.tx-modal__body {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 24px 0;
}
.tx-modal__text-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
}
.gif {
  margin-bottom: 8px;
}
.tx-modal__body__title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.tx-modal__body__subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  opacity: 0.6;
}
.tx-modal__rows {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 16px;
  border: 1px solid #4d4d4d;
  border-radius: 16px;
}
.tx-modal__multi-rows {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
</style>
