<template>
  <Transition>
    <!-- v-if=true to disable vue/require-toggle-inside-transition -->
    <div
      v-if="true"
      ref="bgRef"
      :class="
        'bg ' +
        pagename +
        '_bg--' +
        partsOfDayName +
        ' layout--default page--flex'
      "
    >
      <client-only>
        <y-header
          ref="headerRef"
          app="ui"
          show-language
          show-tx-history
          :nav-links="NAV_LINKS"
        ></y-header>
        <SwitchNetworkAlert />
        <slot></slot>
        <y-footer></y-footer>
        <TxModal></TxModal>
      </client-only>
    </div>
  </Transition>
</template>
<script setup lang="ts">
import SwitchNetworkAlert from '@base/components/others/SwitchNetworkAlert.vue'
import TxModal from '@base/components/others/tx-modal/TxModal.vue'

useLayout()
useDevPasswordAuth()
const { pagename, partsOfDayName } = useBackground()
</script>
<style lang="postcss" scoped>
/* purgecss start ignore */
.layout--default {
  & :deep(.switch-network-container + div) {
    display: flex;
    flex: 1;
  }
}

.bg {
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
  background-position: center !important;
  background-size: cover !important;
  transition: background-image 2s !important;
}

.account_bg--dawn {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../assets/bg/Account_Dawn@m.webp');
}

.account_bg--morning {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../assets/bg/Account_Morning@m.webp');
}

.account_bg--afternoon {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../assets/bg/Account_Afternoon@m.webp');
}

.account_bg--sunset {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../assets/bg/Account_Sunset@m.webp');
}

.account_bg--night {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../assets/bg/Account_Night@m.webp');
}

.claim_bg--dawn {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../assets/bg/Claim_Dawn@m.webp');
}

.claim_bg--morning {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../assets/bg/Claim_Morning@m.webp');
}

.claim_bg--afternoon {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../assets/bg/Claim_Afternoon@m.webp');
}

.claim_bg--sunset {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../assets/bg/Claim_Sunset@m.webp');
}

.claim_bg--night {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../assets/bg/Claim_Night@m.webp');
}

._bg--dawn {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../assets/bg/DeOrder_Dawn@m.webp');
}

._bg--morning {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../assets/bg/DeOrder_Morning@m.webp');
}

._bg--afternoon {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../assets/bg/DeOrder_Afternoon@m.webp');
}

._bg--sunset {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../assets/bg/DeOrder_Sunset@m.webp');
}

._bg--night {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../assets/bg/DeOrder_Night@m.webp');
}

.lock_bg--dawn {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../assets/bg/Lock_Dawn@m.webp');
}

.lock_bg--morning {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../assets/bg/Lock_Morning@m.webp');
}

.lock_bg--afternoon {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../assets/bg/Lock_Afternoon@m.webp');
}

.lock_bg--sunset {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../assets/bg/Lock_Sunset@m.webp');
}

.lock_bg--night {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../assets/bg/Lock_Night@m.webp');
}

@media screen and (max-width: 1024px) {
  div[class*='_bg--'].layout--default {
    background: #151515;
  }
}
/* purgecss end ignore */
</style>
